var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "section hero is-fullheight" }, [
      _c("div", { staticClass: "hero-body" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "column is-4 is-offset-4 has-text-centered" },
            [
              !_vm.errorLogin
                ? _c(
                    "h1",
                    {
                      staticClass:
                        "is-size-1-mobile is-size-1-desktop is-size-1-tablet has-text-primary",
                      staticStyle: {
                        position: "fixed",
                        bottom: "60%",
                        left: "40%",
                        right: "40%"
                      }
                    },
                    [
                      _c("span", { staticClass: "has-text-weight-bold" }, [
                        _vm._v("Rely")
                      ]),
                      _vm._v(" Admin\n          ")
                    ]
                  )
                : _c(
                    "h1",
                    {
                      staticClass:
                        "is-size-1-mobile is-size-1-desktop is-size-1-tablet has-text-primary"
                    },
                    [
                      _c("span", { staticClass: "has-text-weight-bold" }, [
                        _vm._v("Rely")
                      ]),
                      _vm._v(" Admin\n          ")
                    ]
                  )
            ]
          ),
          _c(
            "div",
            { staticClass: "column is-4 is-offset-4" },
            [
              _c("b-field", { attrs: { position: "is-centered" } }, [
                !_vm.errorLogin
                  ? _c(
                      "div",
                      [
                        _c("b-loading", {
                          attrs: {
                            "is-full-page": true,
                            active: "true",
                            "can-cancel": true
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.errorLogin
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "center"
                        }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-accent is-medium is-fullwidth",
                            class: { "is-loading": _vm.showLoader },
                            on: { click: _vm.onLoginSubmit }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("forms.buttons.retrySignIn")) +
                                "\n              "
                            )
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "button is-medium",
                            class: { "is-loading": _vm.showLoader },
                            staticStyle: {
                              width: "80px",
                              height: "30px",
                              "font-size": "12px",
                              "margin-top": "15px"
                            },
                            on: { click: _vm.logOut }
                          },
                          [_vm._v("\n                Logout\n              ")]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("div", {
            staticClass: "column is-4 is-offset-4 has-text-centered"
          }),
          _c(
            "div",
            { staticClass: "column is-6 is-offset-3" },
            [
              _c(
                "b-message",
                {
                  attrs: { active: _vm.errorLogin, type: "is-danger" },
                  on: {
                    "update:active": function($event) {
                      _vm.errorLogin = $event
                    }
                  }
                },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errorLogin,
                          expression: "errorLogin"
                        }
                      ],
                      staticClass: "has-text-centered"
                    },
                    [_vm._v(_vm._s(_vm.errorLogin))]
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }