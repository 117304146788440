<template>
  <section>
    <div class="section hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="column is-4 is-offset-4 has-text-centered">
            <h1
              v-if="!errorLogin"
              class="is-size-1-mobile is-size-1-desktop is-size-1-tablet has-text-primary"
              style="position: fixed; bottom: 60%; left: 40%; right: 40%"
            >
              <span class="has-text-weight-bold">Rely</span> Admin
            </h1>
            <h1 v-else class="is-size-1-mobile is-size-1-desktop is-size-1-tablet has-text-primary">
              <span class="has-text-weight-bold">Rely</span> Admin
            </h1>
          </div>
          <div class="column is-4 is-offset-4">
            <b-field position="is-centered">
              <div v-if="!errorLogin">
                <b-loading :is-full-page="true" active="true" :can-cancel="true"></b-loading>
              </div>

              <div v-if="errorLogin" style="display: flex; flex-direction: column; align-items: center">
                <button :class="{ 'is-loading': showLoader }" class="button is-accent is-medium is-fullwidth" @click="onLoginSubmit">
                  {{ $t('forms.buttons.retrySignIn') }}
                </button>
                <button
                  :class="{ 'is-loading': showLoader }"
                  class="button is-medium"
                  @click="logOut"
                  style="width: 80px; height: 30px; font-size: 12px; margin-top: 15px"
                >
                  Logout
                </button>
              </div>
            </b-field>
          </div>
          <div class="column is-4 is-offset-4 has-text-centered"></div>
          <div class="column is-6 is-offset-3">
            <b-message :active.sync="errorLogin" type="is-danger">
              <p v-show="errorLogin" class="has-text-centered">{{ errorLogin }}</p>
            </b-message>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'login',
  data() {
    return {
      error: null,
      success: { hasSuccess: false },
      code: null
    }
  },
  computed: {
    ...mapGetters(['showLoader', 'loginSuccess', 'errorLogin'])
  },
  created() {},
  mounted() {
    this.code = this.$route.query.code
    if (this.code) {
      this.onGetTokens()
    } else {
      this.signIn()
    }
  },
  watch: {
    loginSuccess() {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    ...mapActions(['signIn', 'getAuthUser', 'signOut']),
    showErrorMessages({ ...error }) {
      this.error = error
    },
    onLoginSubmit() {
      this.signIn()
    },
    onGetTokens() {
      this.getAuthUser(this.code)
    },
    logOut() {
      this.signOut()
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.is-radiusless > input {
  border-radius: 0;
}
</style>
